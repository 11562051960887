import React from "react"
import IconButton from "@material-ui/core/IconButton"
import Brightness4 from "@material-ui/icons/Brightness4"
import Brightness5 from "@material-ui/icons/Brightness5"

import { useThemeStore } from "../../styles-decorator"

function DarkModeButton(props) {
  const { darkMode, setDarkMode } = useThemeStore()

  return (
    <IconButton onClick={() => setDarkMode(!darkMode)} {...props}>
      {darkMode === "dark" ? <Brightness5 /> : <Brightness4 />}
    </IconButton>
  )
}

export default DarkModeButton
