import React from "react"
import styled from "styled-components"
import { Link as GaLink } from "gatsby"
import Img from "gatsby-image"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import MuiLink from "@material-ui/core/Link"
import Slide from "@material-ui/core/Slide"
import Container from "@material-ui/core/Container"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"

import DarkModeButton from "../darkModeButton"

const HEADER_LINKS = [
  {
    label: "About",
    href: "/about",
  },
  {
    label: "Contact",
    href: "/contact",
  },
]

const HeaderInner = styled.div`
  flex-grow: 1;

  .grow-wrap {
    flex-grow: 1;
  }

  .homepage {
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: ${props => props.theme.spacing(2)}px;
    width: 40px;
    height: 40px;
  }

  .links-root {
    > a {
      margin-left: ${props => props.theme.spacing(2)}px;
    }
  }

  .company-name {
    display: none;

    @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
      display: block;
    }
  }
`

function HideOnScroll(props) {
  const { children } = props
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const Header = props => {
  return (
    <HeaderInner>
      <HideOnScroll>
        <AppBar color="inherit">
          <Container maxWidth="lg">
            <Toolbar style={{ padding: 0 }}>
              <div
                data-sal="slide-right"
                data-sal-delay="100"
                data-sal-duration="800"
                data-sal-easing="ease-out-cubic"
                className="grow-wrap"
              >
                <MuiLink
                  component={React.forwardRef((props, ref) => (
                    <GaLink innerRef={ref} {...props} />
                  ))}
                  color="inherit"
                  to="/"
                  className="homepage"
                >
                  <Img
                    fluid={props.data.file.childImageSharp.fluid}
                    alt="Company logo"
                    className="icon"
                  />
                  <Typography
                    component="h4"
                    variant="button"
                    color="inherit"
                    noWrap
                    className="company-name"
                  >
                    Vu Consulting
                  </Typography>
                </MuiLink>
              </div>

              <div
                data-sal="slide-left"
                data-sal-delay="100"
                data-sal-duration="800"
                data-sal-easing="ease-out-cubic"
              >
                <Typography className="links-root">
                  {HEADER_LINKS.map(section => (
                    <MuiLink
                      component={React.forwardRef((props, ref) => (
                        <GaLink innerRef={ref} {...props} />
                      ))}
                      color="inherit"
                      noWrap
                      key={section.href}
                      to={section.href}
                    >
                      {section.label}
                    </MuiLink>
                  ))}
                  <DarkModeButton />
                </Typography>
              </div>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
    </HeaderInner>
  )
}

export default Header
