import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HeadPresenter from "./head"

const Head = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return <HeadPresenter {...props} data={data} />
}

export default Head
