import React from "react"
import styled from "styled-components"

import Header from "../header"
import Footer from "../footer"
import StylesDecorator from "../../styles-decorator"

const Inner = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content {
    flex-grow: 1;
  }
`

const Layout = props => {
  return (
    <Inner>
      <StylesDecorator>
        <div className="content">
          <Header />
          {props.children}
        </div>
        <Footer />
      </StylesDecorator>
    </Inner>
  )
}

export default Layout
