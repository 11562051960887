import React from "react"
import styled from "styled-components"
import { Link as GaLink } from "gatsby"

import Typography from "@material-ui/core/Typography"
import MuiLink from "@material-ui/core/Link"
import Container from "@material-ui/core/Container"

import LinkedInIcon from "@material-ui/icons/LinkedIn"
import TwitterIcon from "@material-ui/icons/Twitter"
import { useTheme } from "@material-ui/core/styles"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import MailOutlineIcon from "@material-ui/icons/MailOutline"

const FooterInner = styled.footer`
  padding: ${props => props.theme.spacing(5, 2)};

  .border-top {
    border-top: 1px solid #e0e0e0;
    padding-top: ${props => props.theme.spacing(6)}px;
  }
`

const ContactDetail = styled.div`
  margin: ${props => props.theme.spacing(1, 0)};

  .mail-grid-container {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 4px;

    .icon-wrap {
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
`

const Socials = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing(3)}px;
  margin: ${props => props.theme.spacing(2, 0)};
  justify-content: center;
  align-items: center;
  grid-auto-flow: column;

  .icon {
    width: 28px;
    height: 28px;
  }
`
const ParentGridContainer = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing(3)}px;
  grid-auto-flow: row;
  align-items: center;
  justify-items: center;
  margin: ${props => props.theme.spacing(5, 0)};

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    grid-auto-flow: column;
  }
`

const CustomContainer = styled(Container)`
  padding: ${props => props.theme.spacing(2, 0)};
`

const Copyright = () => {
  return (
    <Container maxWidth="lg">
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        css={`
          font-style: italic;
          padding-bottom: ${props => props.theme.spacing(2)}px;
        `}
      >
        In the spirit of reconciliation, Vu Consulting acknowledges the
        Traditional Custodians of country throughout Australia and their
        connections to land, sea and community. We pay our respect to their
        Elders past and present and extend that respect to all Aboriginal and
        Torres Strait Islander peoples.
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <MuiLink
          component={React.forwardRef((props, ref) => (
            <GaLink innerRef={ref} {...props} />
          ))}
          color="inherit"
          to="/"
        >
          Vu Consulting Pty Limited
        </MuiLink>{" "}
        {new Date().getFullYear()}
        {"."} - All rights reserved.
      </Typography>
    </Container>
  )
}

const Author = () => {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ paddingTop: 16, opacity: "0.9" }}
    >
      {"Design and Developed by"}{" "}
      <MuiLink
        color="inherit"
        noWrap
        href="https://www.linkedin.com/in/watarusugimura"
        rel="noopener noreferrer"
        target="blank"
        aria-label="Wataru LinkedIn Profile"
      >
        Wataru
      </MuiLink>
    </Typography>
  )
}

const Footer = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <FooterInner>
      <Container maxWidth="lg" className="border-top">
        <Typography variant="h4" align="center" gutterBottom>
          Get In Touch
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          If you’ve got questions on how to boost your brand or would like to
          partner on a project, get in touch.
        </Typography>
      </Container>

      <CustomContainer maxWidth="sm">
        <ParentGridContainer>
          <div>
            <Typography
              gutterBottom
              variant="h6"
              component="h6"
              align={matches ? "left" : "center"}
            >
              {/* Contact */}
            </Typography>
            <ContactDetail>
              <div className="mail-grid-container">
                <div className="icon-wrap">
                  <MailOutlineIcon />
                </div>

                <MuiLink href="mailto:mylan@vu-consulting.com" variant="h6">
                  mylan@vu-consulting.com
                </MuiLink>
              </div>
            </ContactDetail>
          </div>
          <Socials>
            <MuiLink
              color="inherit"
              noWrap
              href="https://www.linkedin.com/company/vuconsulting/"
              rel="noopener noreferrer"
              target="blank"
              aria-label="Linkedin"
            >
              <LinkedInIcon className="icon" />
            </MuiLink>

            <MuiLink
              color="inherit"
              noWrap
              href="https://twitter.com/mylanvu"
              rel="noopener noreferrer"
              target="blank"
              aria-label="Twitter"
            >
              <TwitterIcon className="icon" />
            </MuiLink>
          </Socials>
        </ParentGridContainer>
      </CustomContainer>
      <Copyright />
      <Author />
    </FooterInner>
  )
}

export default Footer
