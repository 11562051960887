import React, { useContext } from "react"
import { ThemeProvider as StyledThemeProvider } from "styled-components"
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles"

import initialTheme from "./theme"
import useLocalStorage from "./hook/useLocalStorage"

const ThemeContext = React.createContext({
  darkMode: false,
  setDarkMode: () => {},
})

export const useThemeStore = () => useContext(ThemeContext)

const StylesDecorator = props => {
  const [darkMode, setDarkMode] = useLocalStorage("darkMode", false)

  let theme = React.useMemo(() => {
    return createTheme({
      ...initialTheme,
      palette: {
        ...initialTheme.palette,
        type: darkMode ? "dark" : "light",
      },
    })
  }, [darkMode])

  theme = responsiveFontSizes(theme)

  return (
    <ThemeContext.Provider
      value={{
        darkMode,
        setDarkMode,
      }}
    >
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <CssBaseline />
          {props.children}
        </StyledThemeProvider>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export default StylesDecorator
